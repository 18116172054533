
.work-bench-right {
  width: 360px;
  background: #fff;
  border-radius: 10px;
  margin-left: 20px;
  .bench-content-wrapper {
    padding: 30px;
    .bench-head {
      display: flex;
      align-items: center;
      position: relative;
      .account-list-box{
        width: 290px;
        position: absolute;
        left: 0;
        top: 114px;
        background: url("./../../assets/img/image/account-triangle-bj.png") no-repeat;
        background-size: 100% 100%;
        padding: 16px 10px;
        overflow: hidden;
        box-sizing: border-box;
      }
      .account-list{
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        height: 170px;
        ::v-deep.el-scrollbar__wrap {
          overflow-x: hidden;
        }
        .account-item{
          padding: 0 10px;
          line-height: 40px;
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover{
            background: #F1F0FE;
            color: #5B51EF;
          }
          .account-item-avatar{
            width: 27px;
            height: 27px;
            border-radius: 50%;
            overflow:hidden;
            margin-right: 8px;
            img{
              max-height: 100%;
              max-width: 100%;
            }
          }
        }
        .itemActive{
          background: #F1F0FE;
          color: #5B51EF;
        }
      }
    }
    .bench-head-avatar {
      width: 90px;
      height: 90px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .bench-head-info {
      width: 1%;
      flex: 1;
      line-height: 1;
      margin-left: 15px;
      color: #999;
      font-size: 14px;
      .name {
        color: #333;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        i{
          color: #333;
          font-size: 10px;
          cursor: pointer;
          transition: 0.3s;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .isUnfold{
          transform: rotate(180deg);
        }
        //&:hover {
        //  color: #20C997;
        //}
      }
      .account {
        margin-top: 10px;
      }
      .info {
        margin-top: 10px;
        span {
          display: inline-block;
          margin-right: 6px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }

    }
    .bench-summary {
      margin-top: 20px;
      display: flex;
      .summary-content {
        width: 1%;
        flex: 1;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .bench-ranking {
      margin-top: 20px;
      .ranking-item {
        display: inline-flex;
        align-items: center;
        background: #FF1616;
        color: #fff;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        padding: 0 15px 0 2px;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
          background: #FFB624;
        }
        .rank-icon {
          width: 20px;
          height: 20px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          overflow: hidden;
          background: #fff url("../../assets/img/image/wb-hg-icon.png") no-repeat center;
        }
        .text {
          margin-left: 10px;
          .num {
            margin: 0 4px;
          }

        }
      }
    }
    .bench-data-overview {
      margin-top: 40px;
      .data-title {
        font-size: 16px;
      }
    }
    .data-list {
      color: #999;
      .data-item {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 0 10px;
        &:first-of-type {
          padding-bottom: 38px;
          border-bottom: 1px solid #ededed;
          margin-top: 28px;
        }
        &:nth-child(2){
          margin-top: 38px;
        }
        &:nth-child(3){
          margin-top: 32px;
          padding-bottom: 38px;
          border-bottom: 1px solid #ededed;
        }
        //&:nth-child(4){
        //  padding: 0;
        //  display: flex;
        //  align-items: center;
        //  margin-top: 34px;
        //  .item-num{
        //    margin-top: 0;
        //  }
        //  .item-right{
        //    display: inline-block;
        //    .item-num{
        //      background: #584EEE;
        //      color: #fff;
        //      border-radius: 10px;
        //      padding: 7px 11px;
        //      font-size: 12px;
        //      cursor: pointer;
        //    }
        //  }
        //}
        //&:last-of-type{
        //  //border-bottom: 1px solid #ededed;
        //  padding: 0 0 15px;
        //  margin-top: 28px;
        //  .item-left,.item-right{
        //    margin-top: 0;
        //  }
        //}
      }
      .item-left, .item-right{
        //width: 50%;
        //margin-top: 15px;
        display: flex;
        flex-direction: column;
        line-height: 1;
      }
      .item-num {
        font-size: 18px;
        color: #333;
        line-height: 1;
        margin-top: 10px;
      }
    }
    .bench-data-oper {
      margin-top: 40px;
      text-align: center;
    }

  }
  .bench-nodata-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    .bench-nodata-img {
      width: 200px;
      img {
        max-width: 100%;
      }
    }
    .bench-nodata-text {
      margin: 30px 0 40px;
      color: #666;
      font-size: 14px;
    }
  }
  .big-green {
    color: #20C997;
  }
}

