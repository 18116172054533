
.broadcaster {
    width: 1260px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .code-title {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: 400;
        color: #409eff;
    }
}

.video-detail {
    display: flex;
    align-items: center;

    .video-img {
        width: 105px;
        height: 130px;
        cursor: pointer;
        img {
            width: 105px;
            height: 130px;
        }
    }

    .video-right {
        padding-left: 16px;
        flex: 1;

        .video-info {
            line-height: 1.5;

            .video-link {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
                display: block;
                color: #333;
                padding-bottom: 16px;

                &:hover {
                    color: #0824CE;
                }
            }
        }

        .tag {
            padding-bottom: 16px;
            display: flex;

            .tag-title {
                display: inline-block;
                min-width: 38px;
                font-size: 12px;
                font-weight: 400;
                color: #999;
            }

            .tag-list {
                display: inline-block;
                max-height: 56px;
                overflow: hidden;

                span {
                    padding: 0 10px;
                    background: rgba(244, 244, 244, 1);
                    border-radius: 12px;
                    display: inline-flex;
                    margin: 0 10px 6px 0;
                }
            }
        }
        .publish-time {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999;
        }
    }
}
.code-gq {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #333;
    opacity: .1;
    color: #fff;

}
::v-deep .el-table--border::after, ::v-deep .el-table--group::after, ::v-deep .el-table::before {
    background-color: transparent;
}

::v-deep .el-table__body-wrapper {
    min-height:722px;
}
