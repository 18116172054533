
    ::v-deep .el-tabs__header{
      margin: 0;
    }
    ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav{
    border: none;
}
::v-deep .is-active{
            background-color: white;
            // color: red;
            
    }

      .details{
        display: flex;
        //justify-content: start;
        flex-direction: column;
        height: 100%;
        .details-top{
          display: flex;
          position: relative;
          .blue-btn{
            padding: 8px 25px;
            font-size: 14px;
            position: absolute;
            right: 0;
            bottom: 10px;
          }
        }
        .details-bottom{
        // background-color: white;

          position: relative;
        }
        ::v-deep.el-tabs{
            box-shadow: none;
            border: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            background: transparent;
            flex: 1;
            width: 1%;
            min-height: 838px;
            position: relative;
        }
        ::v-deep.el-tabs__header{
            border-bottom: none;
            margin: 0;
        }
        ::v-deep.el-tabs__content{
            flex: 1;
            background: #fff;
            padding-top: 20px;
        }
        .el-tab-pane{
            height: 100%;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
            background: #fff;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item{
            border-left: none;
        }
        ::v-deep.el-tabs__header .is-active {
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            color: #0824ce;
            border-radius: 6px 6px 0 0;
        }
        ::v-deep.el-tabs__item{
            height: 52px;
            line-height: 52px;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
            border: none;
        }
        ::v-deep.el-tabs__item:hover {
            color: #0824ce;
            cursor: pointer;
        }
        .update-content{
            height: 20px;
            position: absolute;
            top: 14px;
            right: 378px;
            .update-title{
                margin: 0 10px;
                font-size: 14px;
                cursor: pointer;
                i{
                    color: #0824ce;
                    margin-right: 10px;
                }
                &:hover{
                    color: #1D33E1;
                }
            }
        }
    }
