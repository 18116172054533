
.data{
    padding: 0 30px;
    height: 100%;
    width: 1200px;
    background-color: white;
    padding-top: 15px;
    /*overflow: auto;*/
    .data-header{
        .data-title{
            font-size: 14px;
            font-weight: 400;
        }
        .data-content{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            line-height: 35px;
            .data-item{
                display: flex;
                flex-direction: column;
                .g-num{
                    font-size: 24px;
                    font-weight: bold;
                    color: #5F55F1;
                }
                .added-this-week{
                    & i{
                        font-size: 14px;
                        color: #FFB624;
                        margin: 0 14px;
                    }
                    & .num{
                        font-size: 14px;
                        font-weight: bold;
                        color: #FFB624;
                    }

                }
            }
            .text{
                color:#666666;
                font-size: 14px;
            }

        }
    }

    .data-trend{
        margin-top: 20px;
    }

    .data-menu{
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        .item {
            line-height: 1;
            display: inline-block;
            padding: 10px 12px;
            cursor: pointer;
            background: #F1F0FE;
            border-radius: 5px 0 0 5px;
            &:hover{
              background: #564CED;
              color: #FFFFFF;
            }
            &:last-of-type {
              border-radius: 0 5px 5px 0;
            }
        }

        .selected{
            background: #564CED;
            color: #FFFFFF;
        }
    }
}

